import type SlimSelectConstructor from 'slim-select';
import { isMobileDevice } from '../utils/is-mobile-device';

const map = new Map<HTMLSelectElement, SlimSelectConstructor>();

async function init(container: Element | Document = document) {
    if (!isMobileDevice()) {
        const elements = Array.from<HTMLSelectElement>(
            container.querySelectorAll('select.slim-select:not(.slim-select-container--initialized)'),
        );

        if (elements.length > 0) {
            const SlimSelect = await import('slim-select' /* webpackChunkName: "slim-select" */).then((m) => m.default);

            elements.forEach((el) => {
                const select = new SlimSelect({
                    select: el,
                    showSearch: false,
                    searchFocus: false,
                    allowDeselectOption: el.classList.contains('slim-select--allow-deselect'),
                    hideSelectedOption: false,
                });
                const parent = el.parentElement;
                map.set(el, select);

                if (parent?.classList.contains('slim-select-container')) {
                    parent.classList.add('slim-select-container--initialized');
                }
            });
        }
    }
}

function destroy(container: Element | Document = document) {
    const elements = Array.from<HTMLSelectElement>(
        container.querySelectorAll('select.slim-select.slim-select-container--initialized'),
    );

    elements.forEach((el) => {
        const select = map.get(el);

        if (select) {
            const parent = el.parentElement;
            select.destroy();
            map.delete(el);

            if (parent?.classList.contains('slim-select-container')) {
                parent.classList.remove('slim-select-container--initialized');
            }
        }
    });
}

function getInstanceByElement(el?: HTMLSelectElement | null) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
