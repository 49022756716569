import { ITransitionData } from '@barba/core';
import { preloader } from '../components/preloader';

export default {
    leave({ current }: ITransitionData): Promise<void> {
        return new Promise((resolve) => {
            preloader.leaveAnimation.restart().then(() => {
                current.container.hidden = true;
                resolve();
            });
        });
    },

    enter(): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            requestAnimationFrame(() => {
                preloader.enterAnimation.restart().then(() => resolve());
            });
        });
    },
};
