import './webpack-imports';
import './sentry';
import './polyfills';
// import './metrics';
import './custom-elements';
import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import menu from './inits/menu';
import headerBlending from './inits/header-blending';
import ajaxForms from './inits/ajax-forms';
import floatedLabels from './inits/floated-labels';
import inputMasks from './inits/masks';
import scrollto from './inits/scrollto';
import popupForms from './inits/popup-forms';
import BaseTransition from './transitions/base';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
// import IndexPageView from './views/index';
import './components/preloader';
import { Cursor } from './components/cursor';
import { ImgSequence } from './custom-elements/ImgSequence/ImgSequence';

document.documentElement.classList.add('js-ready');

const queue = new IdleQueue();

calculateScrollbarWidth();
gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const footer = document.querySelector('.js-footer');
const socList = document.querySelector('.js-soc-list');
const feedbackPopupEl = document.querySelector('app-lit-popup[data-lit-popup="feedback"]');
const initialSequences = Array.from(document.querySelectorAll<ImgSequence>('app-img-sequence:not(.js-preloader-img)'));
let cursor: any;

initialSequences.forEach((el) => {
    el.shouldRender = false;
});

function attemptToInitCursor() {
    if (matchMedia('(pointer: fine)').matches && !cursor) {
        cursor = Cursor();
        (window as any).cursor = cursor;
        cursor.init();
    }
}

attemptToInitCursor();
window.addEventListener('resize', attemptToInitCursor);

initBaseView();

if (footer) {
    headerBlending.init(footer);
}

if (socList) {
    const socListClone = socList.cloneNode(true) as HTMLElement;
    socListClone.classList.remove('soc-list--mobile');
    socListClone.classList.add('soc-list--desktop');
    document.body.appendChild(socListClone);
}

queue.pushTask(() => {
    menu.init();
});

queue.pushTask(() => {
    scrollto.init();
});

queue.pushTask(() => {
    popupForms.init();
});

queue.pushTask(() => {
    if (feedbackPopupEl) {
        ajaxForms.init(feedbackPopupEl);
        floatedLabels.init(feedbackPopupEl);
        inputMasks.init(feedbackPopupEl);
    }
});

document.addEventListener(
    'preloader-leave',
    () => {
        barba.init({
            transitions: [BaseTransition],
            views: [
                // IndexPageView,
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('cards-list-page', () =>
                    import(/* webpackChunkName: "cards-list-page" */ './views/cards').then((m) => m.default),
                ),
                lazyView('timetable-page', () =>
                    import(/* webpackChunkName: "timetable-page", webpackPrefetch: true */ './views/timetable').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('news-page', () =>
                    import(/* webpackChunkName: "news-page", webpackPrefetch: true */ './views/news').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                ),
                lazyView('detail-page', () =>
                    import(/* webpackChunkName: "detail-page" */ './views/detail').then((m) => m.default),
                ),
                lazyView('tour-page', () =>
                    import(/* webpackChunkName: "tour-page" */ './views/tour').then((m) => m.default),
                ),
                lazyView('open-visitation-page', () =>
                    import(/* webpackChunkName: "open-visitation-page" */ './views/open-visitation').then(
                        (m) => m.default,
                    ),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            // prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
            timeout: 5000,
            // requestError: (trigger, action, url, response) => {
            //     // go to a custom 404 page if the user click on a link that return a 404 response status
            //     // const prevUrl = window.history.state.states[window.history.state.states.length - 2]?.url;
            //     if (action === 'click' && response.status === 404) {
            //         barba.go(`${ROOT_PATH}404/`);
            //         // if (prevUrl) {
            //         //     window.addEventListener('popstate', (event) => {
            //         //         event.preventDefault();
            //         //         barba.go(prevUrl);
            //         //     });
            //         // }
            //     }

            //     // prevent Barba from redirecting the user to the requested URL
            //     // this is equivalent to e.preventDefault()
            //     return false;
            // },
            debug: NODE_ENV === 'development',
        });

        initialSequences.forEach((el) => {
            el.shouldRender = true;
        });

        document.documentElement.classList.add('app-ready');
    },
    { once: true },
);

// module.hot?.accept();
