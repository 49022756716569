import gsap from 'gsap';
import delegate from 'delegate';

function init() {
    delegate(document, '[data-scrollto]', 'click', (event: any) => {
        const target = event.delegateTarget as HTMLElement;
        const scrollToSelector = target.dataset.scrollto;

        if (scrollToSelector) {
            const elScrollTo = document.querySelector(scrollToSelector);

            if (elScrollTo) {
                gsap.to(window, { duration: 1.2, scrollTo: elScrollTo, ease: 'expo.inOut' });
            }
        }
    });
}

const _module = { init };

export default _module;
