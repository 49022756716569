const header = document.querySelector<HTMLElement>('.js-header:not(.header-clone)');

function init(section: Element | Document = document) {
    if (header) {
        const clone = header.cloneNode(true) as HTMLElement;
        clone.setAttribute('aria-hidden', 'true');
        Array.from(clone.querySelectorAll('a, button, input, textarea')).forEach((el) => {
            el.setAttribute('tabindex', '-1');
        });
        clone.classList.add('header-clone');
        section.appendChild(clone);
    }
}

const _module = { init };

export default _module;
