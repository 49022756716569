const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            obs.unobserve(entry.target);
            entry.target.classList.add('is-revealed');
        }
    });
});

const selector = '[data-reveal], .wysiwyg--animatable > *:not(img:first-child)';

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.observe(el);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
