import { fetchSwiper } from '../dynamic-modules';

export async function createBaseSlider(container: HTMLElement) {
    const { default: Swiper, Lazy, FreeMode, Navigation } = await fetchSwiper();
    const slider = new Swiper(container, {
        modules: [FreeMode, Lazy, Navigation],
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        freeMode: {
            enabled: true,
            momentumRatio: 0.9,
        },
        preloadImages: false,
        watchSlidesProgress: true,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 2,
        },
        on: {
            beforeInit() {
                const navContainer = container.querySelector('.js-swiper-nav');
                const fragment = document.createDocumentFragment();

                function createButton(type: 'prev' | 'next') {
                    const button = document.createElement('button');
                    button.classList.add(`swiper-button-${type}`);
                    button.innerHTML = `
                        <svg width="35" height="32" viewBox="0 0 35 32">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8 2.764C19.81 1.34 17.83.297 16.592.997a2.026 2.026 0 00-.291.2 3691.332 3691.332 0 00-4.682 3.862L9.054 7.178 6.641 9.172l-2.243 1.85a40.404 40.404 0 01-.947.752c-1.688 1.323-3.618 2.906-2.263 4.212.68.656 1.36 1.311 2.038 1.967.969.938 1.936 1.877 2.9 2.82a388.678 388.678 0 015.43 5.405c.746.76 1.489 1.524 2.226 2.292.674.702 1.342 1.407 2.007 2.117.634.678 1.427.65 2.032.268.743-.468 1.308-1.344 1.312-2.221a4.043 4.043 0 011.387-3.03 4.255 4.255 0 012.736-1.05c.972-.018 2.001-.04 3.016-.069 1.53-.042 3.028-.098 4.248-.172.6-.036 1.134-.076 1.57-.12.233-.025.464-.067.691-.12.696-.163 1.275-.707 1.242-1.639-.021-.612-.039-1.224-.054-1.836a232.05 232.05 0 01-.06-4.096 415.695 415.695 0 01-.003-4.148c.002-.66.006-1.32.01-1.979.002-.57-.185-1.15-.475-1.641-.357-.605-1.028-1.044-1.9-.905-.55.089-1.31.161-2.186.22a97.596 97.596 0 01-4.067.172 5.473 5.473 0 01-3.187-.978 5.385 5.385 0 01-2.3-4.48zm-3.354 6.134a1.643 1.643 0 00-.827-1.305c-.541-.311-1.247-.317-1.796.136l-1.837 1.516-.015.013-.01.009-.628.518-2.49 2.055a4.284 4.284 0 00-1.503 2.59 4.36 4.36 0 00-.037 1.207c.104.96.528 1.894 1.278 2.628a456.798 456.798 0 013.054 3.018l.101.101a348.406 348.406 0 011.43 1.436l.89.903c.415.423 1.04.377 1.43.037a1.04 1.04 0 00.356-.701c.008-.08.019-.156.034-.23.193-.932 1.122-1.7 2.075-1.705a570.1 570.1 0 005.242-.056c.997-.016 2.018-.036 2.992-.06a4.26 4.26 0 002.786-1.121c.17-.156.328-.324.471-.505.62-.78.984-1.777.977-2.856a4.728 4.728 0 00-1.017-2.887 5.048 5.048 0 00-1.077-1.022 4.98 4.98 0 00-2.963-.91c-1.511.032-3.087.043-4.454.034a78.224 78.224 0 01-2.73-.056c-1.094-.047-1.649-.903-1.67-1.757a18.753 18.753 0 00-.062-1.03z" fill="#000"></path>
                        </svg>
                    `;
                    fragment.appendChild(button);
                }

                createButton('prev');
                createButton('next');
                navContainer?.appendChild(fragment);
            },
        },
    });

    slider.wrapperEl.setAttribute('data-cursor', 'drag');

    return {
        instance: slider,
        destroy: () => {
            slider.destroy();
        },
    };
}
