import gsap from 'gsap';
import barba from '@barba/core';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createInitialCommonTimeline } from '../animations/initial-common';
import { ImgSequence } from '../custom-elements/ImgSequence/ImgSequence';

export const preloaderAnimationDuration = 0.5; // sec

export function timeout(ms: number): Promise<NodeJS.Timeout> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function createPreloader() {
    const preloader = document.querySelector('.js-preloader');
    const autoplayedSequences = Array.from(
        document.querySelectorAll<ImgSequence>('app-img-sequence[autoplay]:not(.js-preloader-img)'),
    );
    const wasBodyLocked = false;
    let loaded = false;
    const overlay = document.querySelector<HTMLElement>('.js-preloader-overlay');
    const path = document.querySelector<SVGPathElement>('.js-preloader-overlay-path');
    const preloaderSequence = preloader?.querySelector('.js-preloader-img');

    document.body.classList.add('no-scroll');

    if (preloader) {
        disableBodyScroll(preloader);
    }

    autoplayedSequences.forEach((el) => {
        el._animatedObserver.unobserve(el);
        el.pause();
    });

    function createLeaveAnimation() {
        const proxyObj = { progress: 0 };
        const tl = gsap.timeline({
            paused: true,
            onStart: () => {
                preloader?.classList.remove('is-hidden');
            },
        });
        const tl1 = gsap.timeline({ defaults: { duration: preloaderAnimationDuration, ease: 'power2.in' } });
        const tl2 = gsap.timeline({ defaults: { duration: preloaderAnimationDuration, ease: 'power2.out' } });

        tl1.to(proxyObj, {
            progress: 0.5,
            onUpdate: () => {
                const value = 100 * (1 - proxyObj.progress);
                path?.setAttribute('d', `M 0 100 H 100 V ${value} C 100 ${value} 50 0 0 ${value} Z`);
            },
        }).fromTo(overlay, { yPercent: 50 }, { yPercent: 0 }, 0);

        tl2.to(proxyObj, {
            progress: 1,
            onUpdate: () => {
                const value = 100 * (1 - proxyObj.progress);
                path?.setAttribute('d', `M 0 100 H 100 V ${value} C 100 ${value} 50 0 0 ${value} Z`);
            },
        });

        tl.add(tl1).add(tl2);

        return tl;
    }

    function createEnterAnimation() {
        const proxyObj = { progress: 1 };
        const tl = gsap.timeline({
            paused: true,
            onStart: () => {
                gsap.set(overlay, { scaleY: -1 });
            },
            onComplete: () => {
                preloader?.classList.add('is-hidden');
                gsap.set(overlay, { clearProps: 'scaleY' });
            },
        });
        const tl1 = gsap.timeline({ defaults: { duration: preloaderAnimationDuration, ease: 'power2.in' } });
        const tl2 = gsap.timeline({ defaults: { duration: preloaderAnimationDuration, ease: 'power2.out' } });

        tl1.to(proxyObj, {
            progress: 0.5,
            onUpdate: () => {
                const value = 100 * (1 - proxyObj.progress);
                path?.setAttribute('d', `M 0 100 H 100 V ${value} C 100 ${value} 50 0 0 ${value} Z`);
            },
        }).fromTo(overlay, { yPercent: 0 }, { yPercent: -50 }, 0);

        tl2.to(proxyObj, {
            progress: 0,
            onUpdate: () => {
                const value = 100 * (1 - proxyObj.progress);
                path?.setAttribute('d', `M 0 100 H 100 V ${value} C 100 ${value} 50 0 0 ${value} Z`);
            },
        });

        tl.add(tl1).add(tl2);

        return tl;
    }

    const leaveAnimation = createLeaveAnimation();
    const enterAnimation = createEnterAnimation();

    function leave() {
        leaveAnimation.play().then(() => {
            if (!wasBodyLocked) {
                document.body.classList.remove('no-scroll');

                if (preloader) {
                    enableBodyScroll(preloader);
                }
            }

            document.dispatchEvent(new Event('preloader-leave'));

            autoplayedSequences.forEach((el) => {
                el._animatedObserver.observe(el);
            });

            barba.hooks.afterEnter(() => {
                if (!loaded) {
                    createInitialCommonTimeline().play();
                    loaded = true;
                }
            });

            setTimeout(() => {
                gsap.to(preloader, {
                    duration: 0.3,
                    opacity: 0,
                    onComplete: () => {
                        if (preloader) {
                            preloader.classList.add('is-initial-played', 'is-hidden');
                            gsap.set(preloader, { clearProps: 'all' });
                            preloaderSequence?.remove();
                        }
                    },
                });
            }, 100);
        });
    }

    function loadAsset(img: HTMLImageElement): Promise<void> {
        return new Promise((resolve) => {
            if (img.complete) {
                resolve();
            } else {
                img.onload = () => resolve();
                img.onerror = () => resolve();
            }
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));

        if (images.length > 0) {
            await Promise.all<any>([timeout(1000), ...images.map((img) => loadAsset(img))]);
        }
    }

    return { leave, loadAssetsFromElement, leaveAnimation, enterAnimation } as const;
}

export const preloader = createPreloader();

// Initial load
preloader
    .loadAssetsFromElement(document.body)
    .then(() => timeout(preloaderAnimationDuration * 1000))
    .then(() => preloader.leave());
