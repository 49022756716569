const header = document.querySelector<HTMLElement>('.js-header:not(.header-clone)');
const commonRootMargin = '-3% 0% -97% 0%';
const threshold = [0, 1];
const themes = ['red', 'light', 'dark'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (header) {
            const { theme } = target.dataset;

            if (matchMedia('(max-width: 1024px)').matches) {
                if (entry.isIntersecting) {
                    if (!theme) {
                        themes.forEach((item) => {
                            header.classList.remove(`header-theme--${item}`);
                        });
                        return;
                    }

                    themes
                        .filter((item) => item !== theme)
                        .forEach((item) => {
                            header.classList.remove(`header-theme--${item}`);
                        });
                    header.classList.add(`header-theme--${theme}`);
                }
            } else {
                header.classList.remove(`header-theme--${theme}`);
            }
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();

function createThemeObserver(rootMargin: string) {
    return new IntersectionObserver(callback, { rootMargin, threshold });
}

function init(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        const rootMargin = el.dataset.themeObserverMargin || commonRootMargin;
        const observer = createThemeObserver(rootMargin);
        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });

    if (header) {
        themes.forEach((item) => {
            header.classList.remove(`header-theme--${item}`);
        });
    }
}

const _module = { init, destroy };

export default _module;
