import gsap from 'gsap';

export function createInitialCommonTimeline() {
    const headers = Array.from(document.querySelectorAll('.js-header'));
    const socListItems = Array.from(document.querySelectorAll('.soc-list--desktop .soc-list__item'));
    const tl = gsap.timeline({ paused: true, defaults: { duration: 0.9, ease: 'power3.out' } });

    tl.from(
        '.js-header-btn',
        {
            opacity: 0,
            y: 30,
            onComplete: () => {
                gsap.set('.js-header-btn', { clearProps: 'all' });
            },
        },
        0.5,
    )
        .add(() => {
            headers.forEach((el) => el.classList.add('is-animated'));
        }, 0.5)
        .from(socListItems, { duration: 0.5, opacity: 0, yPercent: 50, stagger: 0.07 }, 0.9);

    return tl;
}
