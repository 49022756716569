export function setButtonLoading(el: HTMLButtonElement) {
    const rect = el.getBoundingClientRect();
    const innerEl = el.querySelector('.btn-inner');

    el.disabled = true;
    Object.assign(el.style, { width: `${rect.width}px`, height: `${rect.height}px` });

    if (innerEl) {
        el.dataset.actualText = innerEl.textContent || '';
        innerEl.textContent = el.dataset.loadingText || 'Загрузка...';
    }
}

export function removeButtonLoading(el: HTMLButtonElement) {
    const innerEl = el.querySelector('.btn-inner');

    el.disabled = false;
    Object.assign(el.style, { width: '', height: '' });

    if (innerEl) {
        innerEl.textContent = el.dataset.actualText || '';
    }
}
