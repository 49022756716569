import './ImgSequence.scss';
import { LitElement, html, css, unsafeCSS } from 'lit';
import LazyLoad from 'vanilla-lazyload';
import _styles from './ImgSequence.ce.scss';

export interface ImgSequence {
    autoplay: boolean;
    fps: number;
    frames: number;
    lazy: boolean;
    ready: boolean;
    shouldRender: boolean;
    src: string;
    imgWidth: number;
    imgHeight: number;
    _animatedObserver: IntersectionObserver;
    _canvasWidth: number;
    _canvasHeight: number;
    _ctx: CanvasRenderingContext2D | null;
    _currentIndex: number;
    _img: HTMLImageElement | ImageBitmap | null;
    _interval: NodeJS.Timeout;
    _ro?: ResizeObserver;
}

const dpr = window.devicePixelRatio;

const observer = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            const target = entry.target as ImgSequence;

            if (entry.isIntersecting) {
                obs.unobserve(entry.target);

                if (target._img instanceof HTMLImageElement) {
                    LazyLoad.load(target._img, {
                        callback_loaded: (el) => {
                            const _el = el as HTMLImageElement;
                            target._fetchAndDecode(_el);
                        },
                    });
                }
            }
        });
    },
    { rootMargin: '1000px 0px 1000px 0px' },
);

const animateObserver = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        const target = entry.target as ImgSequence;

        if (entry.isIntersecting) {
            if (target.autoplay) {
                target.play();
            }
        } else {
            target.pause();
        }
    });
});

/**
 * @attr {Number} fps - Количество кадров в секунду (с какой скоростью проигрывать анимацию).
 * @attr {Number} frames - Общее количество кадров.
 *
 * @slot - <img> or <picture>. Адаптивный спрайт секвенции.
 */
export class ImgSequence extends LitElement {
    constructor() {
        super();
        this._onDocumentVisibilityChange = this._onDocumentVisibilityChange.bind(this);

        this._animatedObserver = animateObserver;
        this.ready = false;
        this.shouldRender = true;

        if ('ResizeObserver' in window) {
            this._ro = new ResizeObserver(() => {
                this._setCanvasDimensions();
            });
        }
    }

    static get properties() {
        return {
            autoplay: {
                type: Boolean,
                value: false,
            },
            ready: {
                type: Boolean,
                value: false,
            },
            shouldRender: {
                type: Boolean,
                value: true,
            },
            fps: {
                type: Number,
                value: 12,
            },
            frames: {
                type: Number,
                value: 1,
            },
            imgWidth: {
                type: Number,
                attribute: 'img-width',
            },
            imgHeight: {
                type: Number,
                attribute: 'img-height',
            },
            lazy: {
                type: Boolean,
                value: false,
            },
            _currentIndex: {
                type: Number,
                value: 0,
            },
            _interval: {
                type: Number,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this._ro?.observe(this);
        document.addEventListener('visibilitychange', this._onDocumentVisibilityChange);

        setTimeout(() => {
            const canvas = this.renderRoot.querySelector('canvas');

            if (canvas) {
                this._ctx = canvas.getContext('2d');
            }

            this._img = this.querySelector('img');

            if (this._img) {
                if (this.lazy && !this._img.src) {
                    observer.observe(this);
                } else {
                    this._fetchAndDecode(this._img);
                }
            }
        }, 1);

        animateObserver.observe(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.pause();
        document.removeEventListener('visibilitychange', this._onDocumentVisibilityChange);

        if (this._ro) {
            this._ro.disconnect();
            this._ro = undefined;
        }

        observer.unobserve(this);
        animateObserver.unobserve(this);
    }

    play() {
        clearInterval(this._interval);
        this._interval = setInterval(() => {
            this._currentIndex = this._currentIndex < this.frames - 1 ? this._currentIndex + 1 : 0;

            if (this.shouldRender && this.ready && this._img && this._ctx) {
                this._ctx.clearRect(0, 0, this._canvasWidth, this._canvasHeight);
                this._ctx.drawImage(
                    this._img,
                    this._currentIndex * this.imgWidth,
                    0,
                    this.imgWidth,
                    this.imgHeight,
                    0,
                    0,
                    this._canvasWidth,
                    this._canvasHeight,
                );
            }
        }, 1000 / this.fps);
    }

    pause() {
        clearInterval(this._interval);
    }

    reset() {
        this.pause();
        this._currentIndex = 0;
    }

    restart() {
        this.reset();
        this.play();
    }

    _setCanvasDimensions() {
        const aspect = this.offsetHeight / this.imgHeight;
        this._canvasWidth = Math.round(this.imgWidth * aspect * dpr);
        this._canvasHeight = Math.round(this.imgHeight * aspect * dpr);
    }

    _onDocumentVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.pause();
        } else {
            this.play();
        }
    }

    _fetchAndDecode(img: HTMLImageElement) {
        if ('createImageBitmap' in window) {
            // Decode image off main thread
            fetch(img.currentSrc)
                .then((response) => response.blob())
                .then((blob) => createImageBitmap(blob))
                .then((resultImg) => {
                    this._img = resultImg;
                    this.ready = true;
                });
        } else {
            this.ready = true;
        }
    }

    render() {
        return html`
            <div style="padding-top: ${(this.imgHeight / this.imgWidth) * 100}%;">
                <canvas width="${this._canvasWidth}" height="${this._canvasHeight}"></canvas>
            </div>
        `;
    }
}

customElements.define('app-img-sequence', ImgSequence);

declare global {
    interface HTMLElementTagNameMap {
        'app-img-sequence': ImgSequence;
    }
}
