import barba from '@barba/core';

import { lazyload, videoLazyLoad } from '../inits/lazy-load';
import floatedLabels from '../inits/floated-labels';
import ajaxForms from '../inits/ajax-forms';
import inputMasks from '../inits/masks';
import headerBlending from '../inits/header-blending';
import mobileHeaderTheme from '../inits/mobile-header-theme';
import socListTheme from '../inits/soc-list-theme';
import revealAnimations from '../inits/reveal';
import selects from '../inits/select';
import parallax from '../inits/parallax';
import baseSliders from '../inits/base-slider';
import { isMobileDevice } from '../utils/is-mobile-device';
import { ImgSequence } from '../custom-elements/ImgSequence/ImgSequence';

export function initBaseView() {
    let sequences: ImgSequence[] = [];

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const target = entry.target as ImgSequence;
            target.shouldRender = entry.isIntersecting;
        });
    });

    window.addEventListener('resize', () => {
        if (isMobileDevice()) {
            selects.destroy();
        } else {
            selects.init();
        }
    });

    barba.hooks.beforeEnter((data) => {
        if (data?.next.container) {
            sequences = Array.from(document.querySelectorAll<ImgSequence>('app-img-sequence'));

            sequences.forEach((el) => {
                observer.observe(el);
            });

            Array.from(data.next.container.querySelectorAll('.js-own-header-section')).forEach((section) => {
                headerBlending.init(section);
            });
        }

        selects.init(data?.next.container);
        floatedLabels.init(data?.next.container);
        inputMasks.init(data?.next.container);
        ajaxForms.init(data?.next.container);
        baseSliders.init(data?.next.container);
        parallax.init(data?.next.container);
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
        videoLazyLoad.update();
    });

    barba.hooks.afterEnter((data) => {
        mobileHeaderTheme.init();
        socListTheme.init();
        revealAnimations.init(data?.next.container);
    });

    barba.hooks.beforeLeave((data) => {
        mobileHeaderTheme.destroy();
        socListTheme.destroy();
        floatedLabels.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
        ajaxForms.destroy(data?.current.container);
        revealAnimations.destroy(data?.current.container);
        selects.destroy(data?.current.container);
        parallax.destroy(data?.current.container);

        sequences.forEach((el) => {
            observer.unobserve(el);
        });
        sequences = [];
    });

    barba.hooks.afterLeave((data) => {
        baseSliders.destroy(data?.current.container);
        document.body.classList.remove('no-scroll');
    });
}
