let socList: HTMLElement | null;
const commonRootMargin = '-87% 0% -13% 0%';
const threshold = [0, 1];
const themes = ['red', 'light', 'dark'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (socList) {
            const { theme } = target.dataset;

            if (entry.isIntersecting) {
                if (!theme) {
                    themes.forEach((item) => {
                        socList?.classList.remove(`soc-list-theme--${item}`);
                    });
                    return;
                }

                themes
                    .filter((item) => item !== theme)
                    .forEach((item) => {
                        socList?.classList.remove(`soc-list-theme--${item}`);
                    });
                socList.classList.add(`soc-list-theme--${theme}`);
            }
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();

const observer = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold });

function init(container: Element | Document = document) {
    socList = document.querySelector<HTMLElement>('.soc-list--desktop');

    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });

    if (socList) {
        themes.forEach((item) => {
            socList?.classList.remove(`soc-list-theme--${item}`);
        });
        socList = null;
    }
}

const _module = { init, destroy };

export default _module;
