import barba from '@barba/core';
import { Clock } from '../custom-elements/Clock/Clock';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const popupName = 'menu';
const menuClock = document.querySelector<Clock>('.js-menu-clock');
const menuPopup = document.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);
const menuPopupToggleBtn = document.querySelector<HTMLElement>(`[data-lit-popup-open="${popupName}"]`);
const menuPopupToggleBtnTextEl = menuPopupToggleBtn?.querySelector('.js-menu-btn-text') || null;
const defaultText = menuPopupToggleBtnTextEl?.textContent || '';
let openTimer: NodeJS.Timeout;
let closeTimer: NodeJS.Timeout;
let cursorContainer: HTMLElement | null;
let cursorPrevTheme = '';

function onOpen() {
    clearTimeout(openTimer);
    document.body.classList.add('menu-opened');

    if (menuPopupToggleBtn) {
        Array.from(document.querySelectorAll('.js-menu-btn-text')).forEach((el) => {
            el.textContent = menuPopupToggleBtn.dataset.closeText || '';
        });
    }

    openTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            el.removeAttribute('data-lit-popup-open');
            el.setAttribute('data-lit-popup-close', popupName);
        });
    }, 1);

    if (menuClock) {
        const time = menuClock.getTime();

        if (cursorContainer) {
            cursorPrevTheme = cursorContainer.dataset.theme || '';
        }

        if (time.hours >= 8 && time.hours < 18) {
            // daytime
            menuPopup?.classList.remove('menu--night');

            if (cursorContainer) {
                cursorContainer.dataset.theme = 'light';
            }
        } else {
            // nighttime
            menuPopup?.classList.add('menu--night');

            if (cursorContainer) {
                cursorContainer.dataset.theme = 'dark';
            }
        }
    }
}

function onClose() {
    clearTimeout(closeTimer);
    document.body.classList.remove('menu-opened');

    if (menuPopupToggleBtnTextEl) {
        Array.from(document.querySelectorAll('.js-menu-btn-text')).forEach((el) => {
            el.textContent = defaultText;
        });
    }

    if (cursorContainer) {
        cursorContainer.dataset.theme = cursorPrevTheme;
    }

    closeTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            el.removeAttribute('data-lit-popup-close');
            el.setAttribute('data-lit-popup-open', popupName);
        });
    }, 1);
}

function init() {
    cursorContainer = document.querySelector<HTMLElement>('.js-cursor');

    if (menuPopup) {
        menuPopup.instance.on('open', onOpen);
        menuPopup.instance.on('close', onClose);
        barba.hooks.enter(() => {
            menuPopup.close();
        });
    }
}

function destroy() {
    clearTimeout(closeTimer);
    if (menuPopup) {
        menuPopup.instance.off('open', onOpen);
        menuPopup.instance.off('close', onClose);
    }
}

const _module = { init, destroy };

export default _module;
