import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

type ParallaxOptions = {
    scroller?: string | Element;
    horizontal: boolean;
};

const defaultParallaxOptions = {
    horizontal: false,
};

const map = new WeakMap<HTMLElement, gsap.core.Tween>();

function initTween(el: HTMLElement, { scroller, horizontal }: ParallaxOptions) {
    const direction = el.dataset.direction || 'y';
    const directionUnit = direction === 'y' ? 'vmin' : 'vw';
    const speed = el.dataset.speed ? parseFloat(el.dataset.speed) : 1;
    const rotation = el.dataset.rotation ? parseFloat(el.dataset.rotation) : 0;

    const tween = gsap.to(el, {
        ease: 'none',
        [direction]: `${-100 * speed}${directionUnit}`,
        rotation,
        scrollTrigger: {
            scroller,
            horizontal,
            trigger: el.dataset.targetStart || el,
            endTrigger: el.dataset.targetEnd,
            scrub: true,
            invalidateOnRefresh: true,
        },
    });
    map.set(el, tween);
}

function destroyTween(el: HTMLElement) {
    const tween = map.get(el);
    if (tween) {
        gsap.set(el, { clearProps: 'transform' });
        tween.kill();
        map.delete(el);
    }
}

function init(container: Element | Document = document, _options: Partial<ParallaxOptions> = defaultParallaxOptions) {
    const options = { ...defaultParallaxOptions, ..._options };
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('[data-parallax]'));
    parallaxElements.forEach((el) => {
        const media = el.dataset.parallaxMedia;

        if (media) {
            ScrollTrigger.matchMedia({
                [`screen and ${media}`]: () => initTween(el, options),
                [`not screen and ${media}`]: () => destroyTween(el),
            });
        } else {
            initTween(el, options);
        }
    });
}

function destroy(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('[data-parallax]'));
    parallaxElements.forEach((el) => destroyTween(el));
}

const _module = { init, destroy };

export default _module;
