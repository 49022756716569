import { createBaseSlider } from '../components/base-slider';

const map = new Map<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-base-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.base-slider__slide'));

        if (slides.length > 2) {
            const sliderObj = await createBaseSlider(el);
            map.set(el, sliderObj);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-base-slider')).forEach(async (el) => {
        const sliderObj = map.get(el);

        if (sliderObj) {
            sliderObj.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
